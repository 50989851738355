/*eslint-disable */
// import SubscriptionPlanModel from '../../Model/SubscriptionPlan'
import request from '../../Utils/curl';
import Utility from "../../Utils/utility";

let PayTransactionss = {
  /**
   * payTransactionsList
   */
  async payTransactionsList (context, whereFilter = null) {
    Utility.showLoader = true;
    Utility.loadingMsg = "Please wait...."; 
    try{
      let post_data = new FormData();
      if (whereFilter) {
        post_data.append('filter', JSON.stringify(whereFilter))
      }
      return await request.curl(context, "payTransactions_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at payTransactionsList() and Exception:',err.message)
    }
    finally{
      Utility.showLoader = false;
    }
  },
  /**
  * payUserTransactionsList
  */
  async payUserTransactionsList (context, other_user_id) {
    Utility.showLoader = true;
    Utility.loadingMsg = "Please wait...."; 
    try{
      let post_data = new FormData();
      if (other_user_id) {
        post_data.append('other_user_id', other_user_id)
      }

      return await request.curl(context, "pay_UserTransactions_list", post_data)
        .then(async response => {
          return response
        });
    }
    catch(err){
      console.error('Exception occurred at payUserTransactionsList() and Exception:',err.message)
    }
    finally{
      Utility.showLoader = false;
    }
  },

  /**
   * payTransactionsView
   */
  async payTransactionsView (context, payTransactionsId) {
    try {
      let post_data = new FormData();
      post_data.append('pay_tid', payTransactionsId);
      return await request.curl(context, "payTransactions_view", post_data)
      .then(async response => {
        return response;
      });
    }
    catch (err) {
      console.error("Exception occurred at payTransactionsView() and Exception:",err.message)
    }
    
  },

  /**
   * payTransactionsAdd
   */
  async payTransactionsAdd (context, payTransactionsObj) {
    try{
    let post_data = new FormData();
    
    for (let key in payTransactionsObj) {
      post_data.append(key, payTransactionsObj[key]);
    }

    return await request.curl(context, "payTransactions_add", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at payTransactionsAdd() and Exception:',err.message)
    }
  },

  /**
   * payTransactionsEdit
   */
  async payTransactionsEdit (context, payTransactionsObj) {
    try{
    let post_data = new FormData();
    
    for (let key in payTransactionsObj) {
      post_data.append(key, payTransactionsObj[key]);
    }

    return await request.curl(context, "payTransactions_edit", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at payTransactionsEdit() and Exception:',err.message)
    }
  },

  /**
   * payTransactionsDelete
   */
  async payTransactionsDelete (context, payTransactionsId) {
    try{
    let post_data = new FormData();
    
    post_data.append('pay_tid', payTransactionsId);

    return await request.curl(context, "payTransactions_delete", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.log('Exception occurred at payTransactionsDelete() and Exception:',err.message)
    }
  },
  /**
  * payTransactionsAdd
  */
  async createOrder (context, payTransactionsObj) {
    try{
    let post_data = new FormData();
    
    for (let key in payTransactionsObj) {
      if (payTransactionsObj[key] && typeof payTransactionsObj[key] != 'number') {
        post_data.append(key, payTransactionsObj[key]);
      }
      else if (typeof payTransactionsObj[key] == 'number') {
        post_data.append(key, payTransactionsObj[key]);
      }
    }

    return await request.curl(context, "createOrder", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at createOrder() and Exception:',err.message)
    }
  },
  /**
   * captureOrder
  */
  async captureOrder (context, payTransactionsObj) {
    try{
    let post_data = new FormData();
    
    for (let key in payTransactionsObj) {
      post_data.append(key, payTransactionsObj[key]);
    }

    return await request.curl(context, "captureOrder", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at captureOrder() and Exception:',err.message)
    }
  },

/**
    * payUserTransactionsList
    */
  async payTransactionsDistinctAmountList (context) {
    Utility.showLoader = true;
    Utility.loadingMsg = "Please wait...."; 
    try{
      let post_data = new FormData();

      return await request.curl(context, "payTransactions_distinct_amount_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at payTransactionsDistinctAmountList() and Exception:',err.message)
    }
    finally{
      Utility.showLoader = false;
    }
  },

  /**
    * payUserTransactionsList
    */
  async payTransactionsDistinctTransactionStatusList (context) {
    Utility.showLoader = true;
    Utility.loadingMsg = "Please wait...."; 
    try{
      let post_data = new FormData();

      return await request.curl(context, "payTransactions_distinct_transaction_status_list", post_data)
      .then(async response => {
        return response
      });
    }
    catch(err){
      console.error('Exception occurred at payTransactionsDistinctTransactionStatusList() and Exception:',err.message)
    }
    finally{
      Utility.showLoader = false;
    }
  },
  /**
    * payUserAddSubscriber
    */
   async payUserAddSubscriber (context, subscriberObj) {
    try{
      let post_data = new FormData();

      for (let key in subscriberObj) {
        post_data.append(key, subscriberObj[key]);
      }
  
      return await request.curl(context, "payTransactions_add_subscriber_admin", post_data)
      .then(async response => {
        return response
      });
    }
    catch(err){
      console.error('Exception occurred at payUserAddSubscriber() and Exception:',err.message)
    }
  },
  /**
   * payTransactionsUserProductSubscriptionList
   */
  async payTransactionsUserProductSubscriptionList (context, userIdList) {
    Utility.showLoader = true;
    Utility.loadingMsg = "Please wait...."; 
    try{
      let post_data = new FormData();
      if (userIdList) {
        post_data.append('user_id_list', JSON.stringify(userIdList))
      }
      return await request.curl(context, "payTransactions_user_product_subscription_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at payTransactionsUserProductSubscriptionList() and Exception:',err.message)
    }
    finally{
      Utility.showLoader = false;
    }
  }
}

export {
  PayTransactionss
}
